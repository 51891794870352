import React from 'react'
import "../CSS/modal.css"
import {FaDiscord} from "react-icons/fa";
import {FaInstagram} from "react-icons/fa";
import {FaMailBulk} from "react-icons/fa";
import { useState } from 'react';
import { FaCross } from 'react-icons/fa';
import {motion} from 'framer-motion/dist/framer-motion';
function Modal(props) {
  const [moveUp,setMove] = useState(false);

 
if(moveUp){
  setTimeout(function() {
    props.closeModal()
  }, 500);
}



  return (
    <div onClick={() =>setMove(true)} className="modalContainer">
      <motion.div onClick={() =>setMove(false)} transition={{type:"tween",duration:.5}}  animate={{y:!moveUp?0:-400}} initial={{y:-400}}  className="modal">
        <div className="modalHeader">
          <div></div>
          <h1>Contact Us</h1>
          <span onClick={() =>setMove(true)} class="close">&times;</span>
        </div>
        <div className="contactRow discordContainer">
        <FaDiscord className='faicon' color='white'/>
        <a href="https://discord.gg/SKAUGxkVtz" target="_blank" rel="noopener noreferrer">discord.gg/SKAUGxkVtz</a>
        </div>
        <div className="contactRow gmailContainer">
        <FaMailBulk className='faicon' color='white'/>
          <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank" rel="noopener noreferrer">chsaiclub@gmail.com</a>
        </div>
        <div className="contactRow instagramContainer">
        <FaInstagram className='faicon' color='white'/>
          <a href="https://www.instagram.com/chsaiclub/" target="_blank" rel="noopener noreferrer">@chsaiclub</a>
        </div>
      </motion.div>
    </div>
  );

 


}

export default Modal
