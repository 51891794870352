import React from 'react'
import "../CSS/home.css"
import DotsWave from "../assets/DotsWave.mp4" 
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
function Home() {
  return (
    <div className='homeContainer' id='home'>
      <div className='homeBackgroundLogo'> </div> 
      <div className='backgroundImage'>
         <video className='backgroundVideo' src={DotsWave} autoPlay loop muted playsinline/>
        <div className='backgroundImgTextContainer'> 
          <motion.div transition={{type:"tween",duration:.5}}  animate={{y:0,scale:1,opacity:1}} initial={{y:-100,scale:.7,opacity:.4}}   className='imgTextContainer'>
            <p4>
              
            </p4>
            <p1>
              CHS AI Club
            </p1>
            <p3> 
              
            </p3>
          </motion.div>
        </div>
      </div>
      <div className=''>


      </div>
    </div>
  )
}

export default Home
