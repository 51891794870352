import React from 'react'
import logo from '../assets/hazardlogo.png';
import '../CSS/nav.css'
import { Link } from "react-scroll";
import {FaBars} from "react-icons/fa";
import { useState } from 'react';
import { useEffect } from 'react';
import Modal from "./Modal"
import {motion} from 'framer-motion/dist/framer-motion';
function Nav(props) {

  const [toggleNav, setToggleNav] = useState(true);
  const [toggleModal,setModal] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
 
  const [navBarBackground, setNavBarBackground] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(()=>{
    if(props.yscroll>90){
      setNavBarBackground(true);
    }
    else{
      setNavBarBackground(false);
    }


  },[props.yscroll])

  



  return (
    <motion.div transition={{type:"tween",duration:.5}}  animate={{y:0}} initial={{y:-100}}  className={navBarBackground ? 'navContainer activeBackground' : 'navContainer'}>
      {toggleModal ? <Modal closeModal={()=>contactClick()}/>: null}
      <Link Link smooth={true} offset={-10} duration={500} activeClass="active" to="home"> <img alt="" src={logo}  width="90px"/> </Link>

         <button  className="openBtn" onClick={() => setToggleNav(!toggleNav)}><FaBars style={ {color:"white"}} /></button>
        <ul className={!navBarBackground ? 'activeText' : 'unactiveText'} style={{right: toggleNav && windowSize.innerWidth<650 ? "-70%":"0%"}} > 

            <div>
            <li><Link onClick={() => setToggleNav(true)} smooth={true} offset={-10} duration={500} activeClass="active" to="home" > Home </Link></li>
            <li><Link onClick={() => setToggleNav(true)} Link smooth={true} offset={-170} duration={500} activeClass="active" to="about" > About </Link> </li>
            <li><Link onClick={() => setToggleNav(true)} Link smooth={true} offset={-215} duration={500} activeClass="active" to="board" > Board </Link> </li>
            <li><Link onClick={() => setToggleNav(true)} Link smooth={true} offset={-10} duration={500} activeClass="active" to="resources"  > Resources </Link> </li>
            </div>
            <li><Link onClick={() =>  contactClick()}  className="contactBtn" Link smooth={true} offset={-10} duration={500} activeClass="active" to="contact"> Contact</Link> </li>
        </ul>
        

       
    </motion.div>
    
  )
  function contactClick(){
    setToggleNav(true);
    setModal(!toggleModal);
  }
}
function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
  
}



export default Nav
