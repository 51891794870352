import React from 'react'
import "../CSS/resources.css"
import { motion} from 'framer-motion/dist/framer-motion';
import { useState,useEffect } from 'react';
function Resources(props) {
  const [animateResource, setAnimateResource] = useState(false);

  useEffect(() => {
    if(props.yscroll > 1500) {
      setAnimateResource(true);
    }
  }, [props.yscroll]);
  return (
    <motion.div initial={{scale:.25}} animate={{scale: animateResource?1:0}} transition={{duration:.5, type:"tween"}}>
      <h1 className="resourceTitle">Resources</h1>
    <div className='resourcesContainer' id='resources'>
        <div className="slidesContainer">
          <h1 className="slidesTitle">Slides: </h1>
        <p1 className="slidesText">Coming soon...</p1>
        </div>

        <div className="notebookContainer">
          <h1 className="notebookTitle">Notebooks: </h1>

<a className='resourceLink' target='_blank' href='https://colab.research.google.com/drive/1nPtwiVY6ZFjOl_8AYGZocWh1nI1dvL26?authuser=3#scrollTo=b3TJb4p-AYeT'><p1 className="notebookText">   > How Natural Language Processing (NLP) is Used to Subtitle Videos</p1></a>
<a className='resourceLink' target='_blank' href='https://colab.research.google.com/drive/1hNUKwkmveCl__X0h-fpvKKh0uecfMnI_?authuser=3'><p1 className="notebookText">   > Reinforcement Learning for Video Recommendations</p1></a>
<a className='resourceLink' target='_blank' href='https://colab.research.google.com/drive/1LEvP9kP36pZBTr1TQBlKovNpsmSdziGt?usp=sharing'><p1 className="notebookText">   > Reinforcement Learning for Video Recommendations - Part 2</p1></a>

        </div>

        <div className="externalContainer">
          <h1 className="externalTitle">External Resources: </h1>
<p1 className="externalText">Coming soon...</p1>

        </div>
    </div>
    </motion.div>
  )
}

export default Resources
