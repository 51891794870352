import React from 'react'
import { useState,useEffect } from 'react';
import "../CSS/about.css";
import { motion} from 'framer-motion/dist/framer-motion';
import {Particles} from "react-tsparticles"
import { loadFull } from "tsparticles";
function About(props) {

const [isAnimate,setIsAnimate] = useState(0);

useEffect(()=>{
  if(props.yscroll>300 && isAnimate!==2 && isAnimate!==3) {
    setIsAnimate(1);
  }
  if(props.yscroll>1050 && isAnimate!==3){
    setIsAnimate(2);
  }
  if(props.yscroll>=2000 && (isAnimate==2 || isAnimate==3)){
    setIsAnimate(3);
  }

},[props.yscroll]);


  return (
    <div>
      <Stars/>
    <div className="aboutSectionContainer">

    <div className="aboutTeamContainer" >
      <motion.div initial={{x:-1100}} animate={{x: isAnimate>0?0:-1100}} transition={{duration:.7, type:"tween"}} className="teamInfo">
        <p1 id="about" className="teamTitle">About Us</p1>
        <p2 className="teamInformation">
       CHS AI club is for those interested in artificial intelligence and its applications. We provide hands-on experience with modern libraries along with the opportunity to train robots and compete against other students in machine learning competitions for prizes! Whether you're a beginner or more advanced, we have instructors equipped to handle people of all skill levels — the only pre-requisite is a basic understanding of Python. Come join us to discover the limitless potential of AI!
        </p2>
      </motion.div>
      <motion.div initial={{x:1100}} animate={{x: isAnimate>0?0:1100}} transition={{duration:.7, type:"tween"}} className="teamPicture"></motion.div>

    </div>
    <div className="aboutContainer" >
      <motion.div initial={{x:-1100}} animate={{x: isAnimate>1?0:-1100}} transition={{duration:.7, type:"tween"}}  className="libraryImageContainer">
      </motion.div>
      <motion.div  initial={{x:1100}} animate={{x: isAnimate>1?0:1100}} transition={{duration:.7, type:"tween"}} className="clubContainer">
        <p1 id='board' className="clubTitle">Board</p1>
        <p2 className="clubInformation">
          <b> President: </b> Aryan Sharma<br></br>
          <b> Vice President: </b> Jaden Li and Vinay Vimalkumar<br></br>
          <b> Education Chair: </b> Ramy Kaddouri<br></br>
          <b> Head Developer: </b> Daud Idrees<br></br>
  
          <b> Secretary: </b> Aneesh Potluri<br></br>
          
          <b> Outreach Director: </b> Caleb Shim<br></br>
        
          <b> Member at Large: </b> Joel Chemmanur<br></br>
          

          <br></br>
          Interested in running for a board position? Applications will open near the end of the 2023-2024 school year. Join our Discord server (linked in our <b>Resources</b> module) to stay tuned!
        </p2>
      </motion.div>

    </div>
    
    </div>
    </div>
  )
  
}

const Stars=React.memo(()=>{
  const particlesInit = async (main) => {
    console.log(main);
  
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };
  
  const particlesLoaded = (container) => {
    console.log(container);
  };
  return(
    <motion.div transition={{type:"tween",duration:5}}  animate={{y:0,opacity:1}} initial={{y:0,opacity:.95}} className="particlesContainer">
    <Particles
    id="tsparticles"
    init={particlesInit}
    loaded={particlesLoaded}
        options={{
      background: {
        color: '#000000',
      },
      fpsLimit: 20,
      interactivity: {
        detectsOn: 'canvas',
        events: {
          resize: true
        },
      },
      particles: {
        color: {
          value: "#f1f1f1"
        },
        number: {
          density: {
            enable: true,
            area: 1080
          },
          limit: 0,
          value: 100,
        },
        opacity: {
          animation: {
            enable: true,
            minimumValue: 0.5,
            speed: 1.5,
            sync: false,
          },
          random: {
            enable: true,
            minimumValue: 0.1,
          },
          value: 1,
        },
        shape: {
          type: 'circle',
 
        },
        size: {
          random: {
            enable: true,
            minimumValue: 2
          },
          value: 1
        }
      }
    }}
/>  
</motion.div>
  )
});

export default About
